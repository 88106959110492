<template>
  <div class="Login">
    <div class="Ligin-content">
      <div class="content_left_background">
        <div class="Login_form">
          <div class="new-login">
            <div class="login-box">
              <div class="login-copy">注册</div>
              <div class="login-logo">
                <img src="../assets/icon/logo.png" alt="" />
              </div>
              <div class="login-form">
                <el-form
                  style="width: 100%"
                  ref="RegisterForm"
                  :model="RegisterForm"
                  label-width="100px"
                  size="medium"
                >
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="手机号码">
                        <el-input v-model="RegisterForm.username"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="昵称">
                        <el-input v-model="RegisterForm.nickname"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="真实姓名">
                        <el-input v-model="RegisterForm.realname"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12"
                      ><el-form-item label="密码">
                        <el-input
                          v-model="RegisterForm.password"
                          show-password
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12"
                      ><el-form-item label="身份">
                        <el-radio-group v-model="RegisterForm.is_teacher">
                          <el-radio :label="'1'">老师</el-radio>
                          <el-radio :label="'0'">学生</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row>
                    <el-col :span="24">
                      <el-row>
                        <el-col :span="18">
                          <el-form-item label="图片验证码" prop="name">
                            <el-input
                              placeholder="请输入图片验证码"
                              v-model="RegisterForm.ImgVerification"
                            ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6" style="height: 40px">
                          <img
                            style="height: 100%; width: 100%"
                            @click="Getsend_graphicImg"
                            :src="VerificationCodeImgUrl"
                            alt=""
                          />
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row>
                        <el-col :span="18">
                          <el-form-item
                            label="短信验证码"
                            prop="verification_code"
                          >
                            <el-input
                              v-model="RegisterForm.verification_code"
                              placeholder="请输入短信验证码"
                            ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6" style="height: 40px">
                          <el-button
                            size="medium"
                            @click="GetPhoneVerification"
                            :disabled="!disabledCodeBtn"
                            type="success"
                            >{{ codeText }}</el-button
                          >
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="24">
                      <el-row>
                        <el-col :span="24"
                          ><el-form-item
                            label="老师邮箱"
                            v-if="RegisterForm.is_teacher == 1"
                          >
                            <el-input
                              placeholder="请输入.edu.cn前缀邮箱信息"
                              v-model="RegisterForm.email"
                            >
                              <template style="width: 30%" slot="append"
                                >.edu.cn</template
                              >
                            </el-input>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="18">
                          <el-form-item
                            label="邮箱验证码"
                            prop="email_code"
                            v-if="RegisterForm.is_teacher == 1"
                          >
                            <el-input
                              v-model="RegisterForm.email_code"
                              placeholder="请输入邮箱验证码"
                            ></el-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="6" style="height: 40px">
                          <el-button
                            size="medium"
                            @click="GetEmailVerification"
                            :disabled="!disabledCodeBtn2"
                            type="success"
                            v-if="RegisterForm.is_teacher == 1"
                            >{{ codeText2 }}</el-button
                          >
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form>
                <div style="">
                  <el-button type="primary" @click="ClickRegister"
                    >注 册</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content_right_background"></div>
    </div>
  </div>
</template>

<script>
import {
  send_graphic,
  phone_verification,
  register,
  send_mail,
} from "../api/register";
export default {
  data() {
    return {
      // from表单
      RegisterForm: {
        username: "",
        password: "",
        is_teacher: "0",
        realname: "",
        email: "",
        email_code: "",
        verification_code: "",
      },
      phone_verificationFrom: {
        phone_num: "",
        graphic_code: "",
      },
      VerificationCodeImgUrl: "",
      disabledCodeBtn: true,
      disabledCodeBtn2: true,
      codeText: "获取手机验证码",
      codeText2: "获取邮箱验证码",
    };
  },
  created() {
    console.log(this.$store, 777);
    this.Getsend_graphicImg();
  },
  watch: {
    $route: {
      handler: function (val) {
        console.log(val, 444);
        if (val.path == "/Register") {
          // 获取图形验证码
          this.Getsend_graphicImg();
        }
      },
    },
  },
  methods: {
    // 图形验证码请求
    Getsend_graphicImg() {
      send_graphic().then((res) => {
        this.VerificationCodeImgUrl = "data:image/png;base64," + res;
      });
    },
    //获取邮箱验证码
    GetEmailVerification() {
      if (!this.RegisterForm.email) {
        this.$message({
          message: "请输入邮箱",
          type: "warning",
        });
        return;
      } else {
        this.$store.commit("app/CHANGE_LOADING", true);
        send_mail({
          email: this.RegisterForm.email + ".edu.cn", //
        })
          .then((res) => {
            if (res.code == 200) {
              this.countDown2(60);
              this.$store.commit("app/CHANGE_LOADING", false);
              this.$message({
                type: "success",
                message: "已发送邮件",
              });
            } else {
              this.$store.commit("app/CHANGE_LOADING", false);
            }
          })
          .catch(() => {
            this.$store.commit("app/CHANGE_LOADING", false);
          });
      }
    },
    // 获取手机验证码
    GetPhoneVerification() {
      this.phone_verificationFrom.phone_num = this.RegisterForm.username;
      this.phone_verificationFrom.graphic_code =
        this.RegisterForm.ImgVerification;
      if (!this.phone_verificationFrom.phone_num) {
        this.$message({
          message: "请输入手机号",
          type: "warning",
        });
        return;
      } else if (!this.phone_verificationFrom.graphic_code) {
        this.$message({
          message: "短信验证码不能为空",
          type: "warning",
        });
        return;
      } else {
        this.$store.commit("app/CHANGE_LOADING", true);
        phone_verification(this.phone_verificationFrom)
          .then((res) => {
            if (res.code == 200) {
              this.countDown(60);
              this.$store.commit("app/CHANGE_LOADING", false);
              this.$message({
                type: "success",
                message: "已发送短信",
              });
            } else {
              this.$store.commit("app/CHANGE_LOADING", false);
            }
          })
          .catch(() => {
            this.$store.commit("app/CHANGE_LOADING", false);
          });
      }
    },
    // 手机倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.codeText = "获取验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.codeText = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
    // 邮箱倒计时方法
    countDown2(time) {
      if (time === 0) {
        this.disabledCodeBtn2 = true;
        this.codeText2 = "获取验证码";
        return;
      } else {
        this.disabledCodeBtn2 = false;
        this.codeText2 = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown2(time);
      }, 1000);
    },
    // 注册
    ClickRegister() {
      if (!this.RegisterForm.verification_code) {
        this.$message({
          message: "短信验证码不能为空",
          type: "warning",
        });
        return;
      }
      if (this.RegisterForm.is_teacher == 1) {
        if (!this.RegisterForm.email) {
          this.$message({
            message: "邮箱验证码不能为空",
            type: "warning",
          });
          return;
        }
      }

      this.$store.commit("app/CHANGE_LOADING", true);
      this.$refs["RegisterForm"].validate((valid) => {
        if (valid) {
          var RegisterForm = {};
          RegisterForm = JSON.parse(JSON.stringify(this.RegisterForm));
          if (this.RegisterForm.is_teacher == 1) {
              RegisterForm.email = this.RegisterForm.email + ".edu.cn";
          }
          register({
            register_dict: RegisterForm,
            verification_code: this.RegisterForm.verification_code,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "注册成功",
                });
                this.$store.commit("app/CHANGE_LOADING", false);
                // this.resetForm("RegisterForm");
                this.RegisterForm = {};
                this.phone_verificationFrom = {};
                this.email_codeFrom = {};
                this.$router.push("/login");
              } else {
                this.$store.commit("app/CHANGE_LOADING", false);
              }
            })
            .catch(() => {
              this.$store.commit("app/CHANGE_LOADING", false);
            });
        } else {
          this.$store.commit("app/CHANGE_LOADING", false);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Login {
  width: 100%;
  height: calc(100vh - 80px);
  .Ligin-content {
    width: 100%;
    height: 100%;
    background: aqua;
    display: flex;
    .content_left_background {
      flex: 1;
      background: url("../assets/imgs/img1.jpg") no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
      .Login_form {
        position: absolute;
        top: 50%;
        left: 40%;
        left: 100%;
        left: 150%;
        width: 645px;
        // height: 605px;
        height: 700px;
        background-color: #fff;
        z-index: 10;
        transform: translateY(-50%);
        transform: translate(-50%, -50%);
        box-shadow: 0 0 20px 0px #111;
        box-sizing: border-box;
        .new-login {
          box-sizing: border-box;
          width: 100%;
          // height: calc(100% - 360px);
          height: 645px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-family: AboutCopy, PingFangSC, sans-serif;
          .login-box {
            width: 100%;
            // padding: 20px;
            // border: 1px solid #e1e1e1;
            border-radius: 6.5px;
            position: relative;
            .login-logo {
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              padding: 20px 0 20px 0;
              img {
                // width: 252px;
                width: 120px;
              }
            }
            .login-copy {
              // font-style: italic;
              font-size: 32px;
              font-weight: bold;
              font-family: "微软雅黑";
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              color: #000;
              margin: 10px 0 10px 0;
              padding: 0 10px;
            }
            .login-form {
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              flex-flow: column;
              padding: 0px 40px;
            }
          }
        }
      }
    }
    .content_left_background::before {
      // margin-top: 80px;
      // height: calc(100vh - 80px);
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      // background-color: rgba(#FCC008, 0.9);
      background-color: rgba(#fff, 0.3);
      z-index: 2;
    }
    .content_right_background {
      background: #222;
      flex: 1;
    }
  }
}
</style>
