import request from "../utils/request";

// 获取图形验证码
export function send_graphic(data) {
	return request({
    url: "send_graphic",
    method: "get",
    data: data,
  });
}


// 获取手机验证码
export function phone_verification(data) {
	return request({
    url: "phone_verification",
    method: "POST",
    data: data,
  });
}


// 注册功能
export function register(data) {
	return request({
    url: "register",
    method: "POST",
    data: data,
  });
}

// 发送邮件测试
export function send_mail(data) {
	return request({
    url: "send_mail",
    method: "POST",
    data: data,
  });
}
